import { Box, Button, Toolbar, Avatar, useMediaQuery, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LogoSection from '../../LogoSection';
import { IconMenu2 } from '@tabler/icons';
import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';
import { useDispatch, useSelector } from 'store';
import { LAYOUT_CONST } from 'constant';
import { openDrawer } from 'store/slices/menu';
import { FormattedMessage } from 'react-intl';
import LocalizationSection from '../LocalizationSection';
import ProfileSection from '../ProfileSection';
import { useRouter } from 'next/router';
import { useTranslation } from 'hooks/useTranslation';

const HomeKraquen = () => {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);
  const theme = useTheme();
  const { layout } = useConfig();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const router = useRouter();
  const { translate } = useTranslation();

  return (
    <Toolbar>
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          },
          alignItems: 'center'
        }}
      >
        <Box component="div" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        {((isAuthenticated && layout === LAYOUT_CONST.VERTICAL_LAYOUT) || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd)) && (
          <Tooltip title={!drawerOpen ? translate('viewMenuOptions') : translate('hideMenuOptions')}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                overflow: 'hidden',
                transition: 'all .2s ease-in-out',
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                '&:hover': {
                  background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                  color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                }
              }}
              onClick={() => dispatch(openDrawer(!drawerOpen))}
              color="inherit"
            >
              <IconMenu2 stroke={1.5} size="20px" />
            </Avatar>
          </Tooltip>
        )}
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: { marginRight: 15 } }}>
        <LocalizationSection />
      </Box>
      {isAuthenticated ? (
        <ProfileSection />
      ) : (
        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
          <Button sx={{ marginLeft: 1 }} onClick={() => router.push('/login')}>
            <FormattedMessage id="login" />
          </Button>
        </Box>
      )}
    </Toolbar>
  );
};

export default HomeKraquen;
