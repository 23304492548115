import React, { useEffect, useState } from 'react';
import { Menu, MenuItem, Typography, Box, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import useTeam from 'hooks/useTeam';
import { IUser } from 'interfaces/user';
import { CapitalizeNames } from 'utils/capitalize';
import { useTranslation } from 'hooks/useTranslation';
import { ITeam } from 'interfaces/team';
import { IconBrandAsana } from '@tabler/icons-react';
import { KeyboardArrowUp as KeyboardArrowUpIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import useConfig from 'hooks/useConfig';
import { INDEX_NOT_FOUND } from 'constants/utils';
import { LOCAL_STORAGE_SELECTED_TEAM } from './constants';

interface ISelectGlobalTeam {
  user: IUser;
}

export const SelectGlobalTeam = ({ user }: ISelectGlobalTeam) => {
  const { teams, setTeam, team } = useTeam();
  const [selectedTeamId, setSelectedTeamId] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { translate } = useTranslation();
  const { borderRadius } = useConfig();

  useEffect(() => {
    if (!team?._id) return;
    setSelectedTeamId(team?._id);
  }, [team]);

  const handleTeamChange = (teamId: string) => {
    let selectedTeamIndex = teams?.findIndex((team) => team?._id === teamId);
    let selectedTeam = {};
    if (selectedTeamIndex === INDEX_NOT_FOUND) selectedTeam = teams[0];
    else selectedTeam = teams[selectedTeamIndex];
    localStorage.setItem(LOCAL_STORAGE_SELECTED_TEAM, teamId);
    setTeam(selectedTeam);
    setSelectedTeamId(teamId);
    setAnchorEl(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flexGrow: 1 }}>
      <Typography variant="h5" sx={{ paddingLeft: '16px', mt: 1 }}>
        {translate('Team')}
      </Typography>
      <ListItemButton sx={{ borderRadius: `${borderRadius}px`, mt: 0 }} onClick={handleMenuOpen} disabled={user?.teams?.length === 0}>
        <ListItemIcon>
          <IconBrandAsana stroke={1.5} size="20px" />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              variant="body2"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: 170
              }}
            >
              {user?.teams?.length === 0
                ? translate('NoTeam')
                : CapitalizeNames(teams?.find((team: ITeam) => team._id === selectedTeamId)?.name || '')}
            </Typography>
          }
        />
        {anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </ListItemButton>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {teams?.map((team: ITeam, index: number) => (
          <MenuItem key={index} value={team._id} onClick={() => handleTeamChange(team._id)}>
            {CapitalizeNames(team.name)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
