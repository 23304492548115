import { cloneElement, ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import { AppBar as MuiAppBar, useScrollTrigger } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import { useEffect } from 'react';
import HomeKraquen from './PageHome';
import useTeam from 'hooks/useTeam';
import { LOCAL_STORAGE_SELECTED_TEAM } from 'components/Team/SelectGlobalTeam/constants';

interface ElevationScrollProps {
  children: ReactElement;
  window?: Window | Node;
}

function ElevationScroll({ children, window }: ElevationScrollProps) {
  const theme = useTheme();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window!
  });

  return cloneElement(children, {
    elevation: trigger ? 1 : 0,
    style: {
      backgroundColor: theme.palette.mode === 'dark' && trigger ? theme.palette.dark[800] : theme.palette.background.default,
      color: theme.palette.text.dark
    }
  });
}

const Header = ({ ...others }) => {
  const { user } = useAuth();
  const { getAdvancedSearchTeams, setTeam, teams, team } = useTeam();

  useEffect(() => {
    if (!user?._id) return;

    let teamIds = (user?.teams || []).map((team) => team?._id);
    getAdvancedSearchTeams({ or: [{ _id: teamIds }, { owner: user?._id }] }, { needClearState: false });
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    const storedTeam = localStorage.getItem(LOCAL_STORAGE_SELECTED_TEAM);
    let team = teams?.find((team) => team?._id === storedTeam);
    // eslint-disable-next-line
    setTeam(team || teams?.[0]);
  }, [teams]);

  return (
    <ElevationScroll {...others}>
      <MuiAppBar>
        <HomeKraquen />
      </MuiAppBar>
    </ElevationScroll>
  );
};

export default Header;
