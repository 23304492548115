export const INDEX_NOT_FOUND = -1;
export const DEFAULT_INDEX = 0;
export const EMPTY_STRING = '';
export const FALSE_BOOLEAN = false;
export const TRUE_BOOLEAN = true;

export const YES_STRING = 'yes';

export const SUCCESS_STRING = 'success';
export const ERROR_STRING = 'error';
