import { useEffect, useRef, useState, Fragment } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  ClickAwayListener,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useRouter } from 'next/router';
import Transitions from 'ui-component/extended/Transitions';
import useAuth from 'hooks/useAuth';
import { IconLogout, IconSettings, IconUser, IconSocial, IconUsers } from '@tabler/icons-react';
import useConfig from 'hooks/useConfig';
import Customization from 'layout/Customization';
import { SelectGlobalTeam } from 'components/Team/SelectGlobalTeam';
import { ROUTE_ACCOUNT, ROUTE_SETTINGS_MY_TEAM, ROUTE_SETTINGS_TEAM_AD_ACCOUNTS } from 'constants/routes';
import { useTranslation } from 'hooks/useTranslation';
import { EnhancedBadge } from 'components/Shared/EnhancedBadge';
import { CapitalizeNames as capitalize } from 'utils/capitalize';

const ProfileSection = () => {
  const theme = useTheme();
  const { borderRadius } = useConfig();
  const router = useRouter();
  const { logout, user, updateProfile } = useAuth();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);
  const { translate } = useTranslation();
  const statusMessageRef = useRef<HTMLInputElement>(null);

  const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    if (statusMessageRef.current?.value !== user.status.message) handleAddStatusMessage(statusMessageRef.current?.value || '');

    setOpen(false);
  };

  const HandleClickExit = () => {
    logout();
    router.push(`/`);
  };

  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, route: string, tabIndex?: number) => {
    event.preventDefault();
    if (tabIndex !== undefined) {
      router.push({
        pathname: route,
        query: { tab: tabIndex }
      });
    } else {
      router.push(route);
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const ListComponent = ({ children }: { children: React.ReactNode }) => (
    <List
      component="nav"
      sx={{
        width: '100%',
        maxWidth: 350,
        minWidth: 300,
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
          minWidth: '100%'
        },
        '& .MuiListItemButton-root': {
          mt: 0.5
        }
      }}
    >
      {children}
    </List>
  );

  const handleChangeStatus = () => {
    updateProfile({
      status: {
        ...user.status,
        isActive: !user.status.isActive
      }
    });
  };

  const handleAddStatusMessage = (message: string) => {
    updateProfile({
      status: {
        ...user.status,
        message
      }
    });
  };

  useEffect(() => {
    if (user?.status?.message && statusMessageRef?.current) statusMessageRef.current.value = user.status.message;
  }, [user, statusMessageRef]);

  return (
    <Fragment>
      <Tooltip title={translate('accountOptions')}>
        <Box
          sx={{
            height: 48,
            display: 'flex',
            alignItems: 'center',
            borderRadius: 24,
            transition: 'all .2s ease-in-out',
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
            paddingX: 1,
            gap: 1,
            cursor: 'pointer'
          }}
          data-testid="profile-section-box"
          onClick={handleToggle}
        >
          <EnhancedBadge
            color={user?.status?.isActive ? 'success' : 'error'}
            variant="dot"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <Avatar
              src={user?.image || ''}
              sx={{
                width: 35,
                height: 35,
                cursor: 'pointer'
              }}
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              color="inherit"
              alt={user.name}
            />
          </EnhancedBadge>
          <IconSettings stroke={1.5} size="24px" color={theme.palette.primary.main} style={{ marginLeft: 5 }} />
        </Box>
      </Tooltip>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [50, 20]
            }
          }
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions in={open} {...TransitionProps}>
              <Card elevation={16} sx={{ borderRadius: `${borderRadius}px` }}>
                {open && (
                  <CardContent>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">{translate('welcome')},</Typography>
                        {user && user.name && (
                          <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                            {capitalize(user.name)}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                    <Divider sx={{ mt: 1 }} />
                    <ListComponent>
                      <ListItemButton
                        data-testid="change-status-button"
                        sx={{ borderRadius: `${borderRadius}px` }}
                        onClick={handleChangeStatus}
                      >
                        <ListItemIcon>
                          <Avatar src={user?.image || ''} sx={{ width: 30, height: 30 }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Box display="flex" gap={0.5}>
                              <Typography variant="body2">{translate('Settings.ChangeStatus')}</Typography>
                              <Typography variant="body2" fontWeight={700}>
                                {!user.status.isActive
                                  ? translate('Settings.Active').toLowerCase()
                                  : translate('Settings.Inactive').toLowerCase()}
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItemButton>
                      <ListItem sx={{ borderRadius: `${borderRadius}px` }}>
                        <TextField
                          inputProps={{
                            'data-testid': 'status-message-input'
                          }}
                          inputRef={statusMessageRef}
                          fullWidth
                          size="small"
                          placeholder={translate('Settings.StatusMessage')}
                          defaultValue={user?.status?.message || ''}
                        />
                      </ListItem>
                    </ListComponent>
                    <Divider sx={{ mt: 1 }} />
                    <ListComponent>
                      <ListItemButton
                        sx={{ borderRadius: `${borderRadius}px` }}
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, ROUTE_ACCOUNT)}
                      >
                        <ListItemIcon>
                          <IconUser stroke={1.5} size="20px" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">{translate('myProfile')}</Typography>} />
                      </ListItemButton>

                      <ListItemButton
                        sx={{ borderRadius: `${borderRadius}px` }}
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, ROUTE_SETTINGS_MY_TEAM)}
                      >
                        <ListItemIcon>
                          <IconUsers stroke={1.5} size="20px" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">{translate('team')}</Typography>} />
                      </ListItemButton>

                      <ListItemButton
                        sx={{ borderRadius: `${borderRadius}px` }}
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, ROUTE_SETTINGS_TEAM_AD_ACCOUNTS)}
                      >
                        <ListItemIcon>
                          <IconSocial stroke={1.5} size="20px" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">{translate('dataSource')}</Typography>} />
                      </ListItemButton>

                      <Customization />

                      <ListItemButton sx={{ borderRadius: `${borderRadius}px` }} onClick={HandleClickExit}>
                        <ListItemIcon>
                          <IconLogout stroke={1.5} size="20px" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">{translate('logout')}</Typography>} />
                      </ListItemButton>

                      <Divider sx={{ mb: 1, mt: 1 }} />

                      <SelectGlobalTeam {...{ user }} />
                    </ListComponent>
                  </CardContent>
                )}
              </Card>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </Fragment>
  );
};

export default ProfileSection;
