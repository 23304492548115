import Link from 'Link';
import { Link as MuiLink } from '@mui/material';
import { DASHBOARD_PATH } from 'config';
import Logo from 'ui-component/Logo';
import useAuth from '../../../hooks/useAuth';

const LogoSection = () => {
  const { isAuthenticated } = useAuth();
  const origin = window.location.origin;
  const desiredUrl = isAuthenticated ? `${origin}/${DASHBOARD_PATH}` : origin;

  return (
    <MuiLink component={Link} href={desiredUrl} aria-label="theme-logo">
      <Logo />
    </MuiLink>
  );
};

export default LogoSection;
