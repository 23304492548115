import React from 'react';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 5,
    top: 18,
    borderRadius: '50%',
    padding: 6,
    border: `1px solid ${theme.palette.background.paper}`
  },
  '& .MuiBadge-colorError': {
    backgroundColor: grey[300],
    color: '#fff',
    border: `1px solid ${theme.palette.background.paper}`
  }
}));

export const EnhancedBadge = ({ badgeContent, color = 'default', children, ...props }: BadgeProps) => {
  return (
    <StyledBadge badgeContent={badgeContent} color={color} {...props}>
      {children}
    </StyledBadge>
  );
};
