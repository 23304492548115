import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useAuth from 'hooks/useAuth';
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { IconBrush, IconPlus, IconColorSwatch } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PresetColor from './PresetColor';
import ThemeModeLayout from './ThemeMode';
import MainCard from 'ui-component/cards/MainCard';
import useConfig from 'hooks/useConfig';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  ) as any;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const Customization = () => {
  const theme = useTheme();
  const { isAuthenticated } = useAuth();
  const { onReset, borderRadius } = useConfig();

  // drawer on/off
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      {/* toggle button */}
      <Tooltip title={isAuthenticated ? '' : 'Live Customize'}>
        <ListItemButton
          sx={{
            borderRadius: `${borderRadius}px`,
            padding: isAuthenticated ? '10px 16px' : '3.5px 10px',
            backgroundColor: isAuthenticated ? 'transparent' : theme.palette.secondary.light
          }}
          onClick={handleToggle}
        >
          <ListItemIcon sx={{ color: isAuthenticated ? theme.palette.grey[700] : theme.palette.secondary.main }}>
            <IconBrush stroke={1.5} size="20px" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body2" sx={{ color: isAuthenticated ? theme.palette.grey[700] : theme.palette.secondary.main }}>
                <FormattedMessage id="theme" />
              </Typography>
            }
          />
        </ListItemButton>
      </Tooltip>

      <Drawer anchor="right" onClose={handleToggle} open={open} PaperProps={{ sx: { width: 375 } }}>
        {open && (
          <PerfectScrollbar component="div">
            <MainCard content={false} border={false}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5} sx={{ p: 2.5 }}>
                <Typography variant="h5">Theme Customization</Typography>
                <Stack direction="row" alignItems="center" spacing={1.25}>
                  <Button variant="outlined" color="error" size="small" onClick={() => onReset()}>
                    Reset
                  </Button>
                  <IconButton sx={{ p: 0 }} onClick={handleToggle}>
                    <IconPlus
                      size={24}
                      style={{
                        transform: 'rotate(45deg)',
                        color: theme.palette.grey[600]
                      }}
                    />
                  </IconButton>
                </Stack>
              </Stack>
              <Divider />
              <Box sx={{ width: '100%' }}>
                <Tabs
                  value={value}
                  sx={{
                    bgcolor: theme.palette.mode === 'dark' ? 'dark.800' : 'grey.50',
                    minHeight: 56,
                    '& .MuiTabs-flexContainer': { height: '100%' }
                  }}
                  centered
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label={<IconColorSwatch />} {...a11yProps(0)} sx={{ width: '50%' }} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Grid container spacing={2.5}>
                  <Grid item xs={12}>
                    {/* layout type */}
                    <ThemeModeLayout />
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    {/* Theme Preset Color */}
                    <PresetColor />
                    <Divider />
                  </Grid>
                </Grid>
              </CustomTabPanel>
            </MainCard>
          </PerfectScrollbar>
        )}
      </Drawer>
    </>
  );
};

export default Customization;
